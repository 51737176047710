import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "dropdown" ]
  
  // choose a table
  table(event) {
    event.preventDefault()
    $(this.dropdownTarget).val(event.target.textContent)
    $(this.element)[0].dispatchEvent(new CustomEvent('submit', {bubbles: true}))
  }
}
