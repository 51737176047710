import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    name: String
  }

  connect() {
    // console.log("connect column_controller.js")
  }

  add() {
    let array = $('#probe_columns').val().split(' ')
    array.push( this.nameValue )
    $('#probe_columns').val( array.join(' ') )
    $("#probes_show_form")[0].dispatchEvent(new CustomEvent('submit', {bubbles: true}))
  }

  remove() {
    let array = $('#probe_columns').val().split(' ')
    const myIndex = array.indexOf(this.nameValue);
    if (myIndex > -1) {
      array.splice(myIndex, 1)
    }
    $('#probe_columns').val( array.join() )
    $("#probes_show_form")[0].dispatchEvent(new CustomEvent('submit', {bubbles: true}))
  }
}
