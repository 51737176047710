import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("connect columns_controller.js")
    let that = this
    $(this.element).scroll(function() {
      window.setCookie("scroll1672761427", $(that.element).scrollTop())
      // console.log("set " + window.getCookie('scroll1672761427') )
    })

    if (window.getCookie('scroll1672761427')) {
      // console.log("got ")
      $(this.element).scrollTop( window.getCookie('scroll1672761427') )
    }
  }
}



